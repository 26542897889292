import { default as connexionkroWVxV0ysMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/connexion.vue?macro=true";
import { default as donnees_45personnelles0KYXeiptCJMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/donnees-personnelles.vue?macro=true";
import { default as indexwaZDYlGXHCMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/index.vue?macro=true";
import { default as formulairesGRP89soJkMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import/[k]/formulaire.vue?macro=true";
import { default as index9m4VAIRchnMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import/[k]/index.vue?macro=true";
import { default as _91k_93zBLBsmhvEBMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import/[k].vue?macro=true";
import { default as intentions_45importTuwoT8jHaDMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import.vue?macro=true";
import { default as _91k_93mIhfx855ZgMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions/[k].vue?macro=true";
import { default as mentions_45legalesxKmSocByZCMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mentions-legales.vue?macro=true";
import { default as _91k_93ZbhVjmk41gMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees/[k].vue?macro=true";
import { default as mes_45intentions_45archiveesSOkuwqhunDMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees.vue?macro=true";
import { default as _91k_93B9pFuk1iiCMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions/[k].vue?macro=true";
import { default as mes_45intentionsKfF6GltVGUMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions.vue?macro=true";
import { default as _91k_93blTnXuFSzGMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites/[k].vue?macro=true";
import { default as mes_45opportunitesmfKVuLlaXMMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites.vue?macro=true";
import { default as mesintentionsRcJUCWRYwVMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mesintentions.vue?macro=true";
import { default as mesintentionsArchiveesfsMnOW2emmMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mesintentionsArchivees.vue?macro=true";
import { default as mesopportunites6c3vb5UYMcMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mesopportunites.vue?macro=true";
import { default as _91token_93iVa37vESSMMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue?macro=true";
import { default as index0C4l8kl1MiMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieB6as0nsu8mMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie.vue?macro=true";
import { default as _91k_93ja5qklnR6nMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/opportunites/[k].vue?macro=true";
import { default as indexdzspjYXbPAMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil/index.vue?macro=true";
import { default as profilKsVKolDnXjMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil.vue?macro=true";
import { default as formulairej83n5zJG81Meta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/formulaire.vue?macro=true";
import { default as indexIG17s6G1TBMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/index.vue?macro=true";
import { default as _91k_93vWfdZIKoYAMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k].vue?macro=true";
import { default as sessionspgxLyOWWObMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions.vue?macro=true";
import { default as _91k_93HPpz159zc8Meta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees/[k].vue?macro=true";
import { default as toutes_45les_45intentions_45archivees9roBaxTUPxMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees.vue?macro=true";
import { default as _91k_93HIOVf995eLMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions/[k].vue?macro=true";
import { default as toutes_45les_45intentionsMIdxrxz3PMMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions.vue?macro=true";
import { default as _91k_932BveEo7NLJMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites/[k].vue?macro=true";
import { default as toutes_45les_45opportunitesenVp84lb8LMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites.vue?macro=true";
import { default as touteslesintentions4FzOXcg0OaMeta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/touteslesintentions.vue?macro=true";
import { default as touteslesopportunitesVmRKj13Yf6Meta } from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/touteslesopportunites.vue?macro=true";
export default [
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/donnees-personnelles.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "intentions-import",
    path: "/intentions-import",
    meta: intentions_45importTuwoT8jHaDMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93zBLBsmhvEBMeta?.name,
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "intentions-import-k-formulaire",
    path: "formulaire",
    meta: formulairesGRP89soJkMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "intentions-import-k",
    path: "",
    meta: index9m4VAIRchnMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions-import/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "intentions-k",
    path: "/intentions/:k()",
    meta: _91k_93mIhfx855ZgMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/intentions/[k].vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "mes-intentions-archivees",
    path: "/mes-intentions-archivees",
    meta: mes_45intentions_45archiveesSOkuwqhunDMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees.vue").then(m => m.default || m),
    children: [
  {
    name: "mes-intentions-archivees-k",
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mes-intentions",
    path: "/mes-intentions",
    meta: mes_45intentionsKfF6GltVGUMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions.vue").then(m => m.default || m),
    children: [
  {
    name: "mes-intentions-k",
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mes-opportunites",
    path: "/mes-opportunites",
    meta: mes_45opportunitesmfKVuLlaXMMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites.vue").then(m => m.default || m),
    children: [
  {
    name: "mes-opportunites-k",
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mesintentions",
    path: "/mesintentions",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mesintentions.vue").then(m => m.default || m)
  },
  {
    name: "mesintentionsArchivees",
    path: "/mesintentionsArchivees",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mesintentionsArchivees.vue").then(m => m.default || m)
  },
  {
    name: "mesopportunites",
    path: "/mesopportunites",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/mesopportunites.vue").then(m => m.default || m)
  },
  {
    name: mot_45de_45passe_45oublieB6as0nsu8mMeta?.name,
    path: "/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieB6as0nsu8mMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie.vue").then(m => m.default || m),
    children: [
  {
    name: "mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93iVa37vESSMMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue").then(m => m.default || m)
  },
  {
    name: "mot-de-passe-oublie",
    path: "",
    meta: index0C4l8kl1MiMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "opportunites-k",
    path: "/opportunites/:k()",
    meta: _91k_93ja5qklnR6nMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/opportunites/[k].vue").then(m => m.default || m)
  },
  {
    name: profilKsVKolDnXjMeta?.name,
    path: "/profil",
    meta: profilKsVKolDnXjMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil.vue").then(m => m.default || m),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "sessions",
    path: "/sessions",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93vWfdZIKoYAMeta?.name,
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "sessions-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "sessions-k",
    path: "",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "toutes-les-intentions-archivees",
    path: "/toutes-les-intentions-archivees",
    meta: toutes_45les_45intentions_45archivees9roBaxTUPxMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees.vue").then(m => m.default || m),
    children: [
  {
    name: "toutes-les-intentions-archivees-k",
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "toutes-les-intentions",
    path: "/toutes-les-intentions",
    meta: toutes_45les_45intentionsMIdxrxz3PMMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions.vue").then(m => m.default || m),
    children: [
  {
    name: "toutes-les-intentions-k",
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "toutes-les-opportunites",
    path: "/toutes-les-opportunites",
    meta: toutes_45les_45opportunitesenVp84lb8LMeta || {},
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites.vue").then(m => m.default || m),
    children: [
  {
    name: "toutes-les-opportunites-k",
    path: ":k()",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "touteslesintentions",
    path: "/touteslesintentions",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/touteslesintentions.vue").then(m => m.default || m)
  },
  {
    name: "touteslesopportunites",
    path: "/touteslesopportunites",
    component: () => import("/builds/9542779119/pildom/APPS/NUXT_VOIRIE/pages/touteslesopportunites.vue").then(m => m.default || m)
  }
]