import revive_payload_client_elVimVYjzJ from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_abfjIKdIJl from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2l3KGgRE8n from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_325tplJvj1 from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Uw9SYOlVgA from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_MlXwhWUy9x from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GXO8y2YNBY from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8n8Uy1hOGp from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.32_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/.nuxt/components.plugin.mjs";
import prefetch_client_yqTiBTNPBA from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_io_562017a525399d4887242b7a66a14931/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_q8yHyLyWjb from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import mergeObjects_JbHzNSpMmN from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/plugins/mergeObjects.ts";
import vuetify_DPVY7aMxlt from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/plugins/vuetify.ts";
import notifier_TfOkCLHRTj from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/notifier.ts";
import api_client_t0dWRHSNDZ from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/api.client.ts";
import currentPath_yqNfcRq2j9 from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/currentPath.ts";
import currentUser_28J1aqNENu from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/currentUser.ts";
import translation_NgY8VMsLMG from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/translation.ts";
import getColor_Ghj8WPsFjF from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/getColor.ts";
import helper_Bdxgb2x3zG from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/helper.ts";
import formatDate_za3K2nEobv from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/formatDate.ts";
import getAppInfo_aqy48HiWla from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/plugins/getAppInfo.ts";
import hasAccess_5YDdY3srs4 from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/plugins/hasAccess.ts";
import leaflet_client_7dEOJA4hbk from "/builds/9542779119/pildom/APPS/NUXT_VOIRIE/plugins/leaflet.client.ts";
export default [
  revive_payload_client_elVimVYjzJ,
  unhead_abfjIKdIJl,
  router_2l3KGgRE8n,
  payload_client_325tplJvj1,
  navigation_repaint_client_Uw9SYOlVgA,
  check_outdated_build_client_MlXwhWUy9x,
  chunk_reload_client_GXO8y2YNBY,
  plugin_vue3_8n8Uy1hOGp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yqTiBTNPBA,
  i18n_q8yHyLyWjb,
  mergeObjects_JbHzNSpMmN,
  vuetify_DPVY7aMxlt,
  notifier_TfOkCLHRTj,
  api_client_t0dWRHSNDZ,
  currentPath_yqNfcRq2j9,
  currentUser_28J1aqNENu,
  translation_NgY8VMsLMG,
  getColor_Ghj8WPsFjF,
  helper_Bdxgb2x3zG,
  formatDate_za3K2nEobv,
  getAppInfo_aqy48HiWla,
  hasAccess_5YDdY3srs4,
  leaflet_client_7dEOJA4hbk
]